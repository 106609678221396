import React from 'react';
import Header from 'components/common/header';
import Footer from 'components/common/footer';
import styles from './styles.module.css';

const LayOut = ({ children }) => {
  return (
    <div className={styles.layOutContainerStyles}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default LayOut;
