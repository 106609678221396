import React from 'react';
import Image from 'components/common/image';
import classNames from 'classnames';
import styles from './styles.module.css';

const Input = (props) => {
  const {
    label,
    type,
    name,
    value,
    placeholder,
    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
    image,
    altText,
    onClickRightImg = () => {},
    errorMsg,
    customInputContainerStyles,
    customLabelTextStyles,
    customInputStyles,
  } = props;
  return (
    <div
      className={classNames(
        styles.inputContainerStyles,
        customInputContainerStyles
      )}
    >
      {label && (
        <label
          className={classNames(styles.labelTextStyles, customLabelTextStyles)}
        >
          {label}
        </label>
      )}
      <div className={styles.inputSubContainerStyles}>
        <input
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          className={classNames(styles.inputStyles, customInputStyles)}
        />
        {image && (
          <Image image={image} altText={altText} onClick={onClickRightImg} />
        )}
      </div>
      {errorMsg && <p className={styles.errorMsgStyles}>{errorMsg}</p>}
    </div>
  );
};

export default Input;
