import React, { useState } from 'react';
import Image from 'components/common/image';
import {
  bannerImage,
  linkedinIcon,
  progressBarMobileImg,
  progressMobileStepOneImg,
  progressStepOneImg,
  rightArrowWhiteBgIcon,
  servicesImg,
} from 'resources/images';
import Button from 'components/common/button';
import HowItWorksCard from 'components/common/cards/how-it-works';
import {
  howItWorksData,
  pregressBarData,
  pregressBarMobileData,
  whyChooseUsData,
} from 'constants/commondata.js';
import WhyChooseUsCard from 'components/common/cards/why-choose-us';
import Input from 'components/common/input';
import Modal from 'components/common/modal';
import TextArea from 'components/common/textarea';
import { servicesImageData } from 'constants/dummydata.js';
import styles from './styles.module.css';

const HowItWorks = () => {
  // states:
  const [isModalOpen, setIsModalOpen] = useState({
    bookCallOne: false,
  });

  const [bookCallData, setBookCallData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    description: '',
  });

  const [errorMessage, setErrorMessage] = useState({
    name: '',
    phoneNumber: '',
    email: '',
  });

  const [selectStep, setSelectStep] = useState({ stepTitle: '1' });

  const handleBookCallValidations = () => {
    let errors = {
      name: bookCallData.name.trim() === '' ? 'Please Enter Name' : '',
      phoneNumber:
        bookCallData.phoneNumber.trim() === ''
          ? 'Please Enter phone number'
          : '',
      email: bookCallData.email.trim() === '' ? 'Please Enter email' : '',
    };

    setErrorMessage(errors);

    // Check if there are any non-empty error messages
    const hasErrors = Object.values(errors).some((error) => error !== '');

    if (!hasErrors) {
      setBookCallData({});
      setIsModalOpen({
        ...isModalOpen,
        bookCallOne: false,
      });
    }
  };

  const tapOnServicesStepClick = (item) => {
    setSelectStep(item);
  };

  const renderTopInfoSection = () => {
    return (
      <div className={styles.topInfoContainerStyles}>
        <Image
          image={linkedinIcon}
          altText="linkedinIcon"
          customImageContainerStyles={styles.linkedinImgStyles}
        />

        <div className={styles.topInfoSubContainerStyles}>
          <h1 className={styles.topHeadingStyles}>
            Amplify Your Brand’s Voice with Employee Advocacy.
          </h1>
          <p className={styles.topDescStyles}>
            Reposted AI seamlessly integrates with LinkedIn to magnify your
            content reach through automated employee reposts. Empower your team
            to become brand ambassadors with minimal effort and maximum impact
          </p>
        </div>

        <Button
          title="Book a call"
          image={rightArrowWhiteBgIcon}
          altText="rightArrowWhiteBgIcon"
          onClick={() =>
            setIsModalOpen({
              ...isModalOpen,
              bookCallOne: !isModalOpen.bookCallOne,
            })
          }
          customBtnContainerStyles={styles.btnContainerStyles}
          customBtnTitleStyles={styles.bookCallBtnTitleStyles}
        />
        {renderBookACallModalSection()}
      </div>
    );
  };

  const renderBookACallModalSection = () => {
    return (
      <Modal
        isOpen={isModalOpen.bookCallOne}
        customModalContentStyles={styles.bookACallModalStyles}
        customModalOverLayStyles={styles.bookACallModalOverLayStyles}
      >
        <div className={styles.inputContainerStyles}>
          <div className={styles.inputSubContainerStyles}>
            <Input
              label="Name"
              type="text"
              name="text"
              placeholder="Enter your name"
              value={bookCallData.name}
              onChange={(e) =>
                setBookCallData({ ...bookCallData, name: e.target.value })
              }
              errorMsg={errorMessage.name}
              onFocus={() => setErrorMessage({ ...errorMessage, name: '' })}
            />
            <Input
              label="phone number"
              type="text"
              name="text"
              placeholder="Enter your phone number"
              value={bookCallData.phoneNumber}
              onChange={(e) =>
                setBookCallData({
                  ...bookCallData,
                  phoneNumber: e.target.value,
                })
              }
              errorMsg={errorMessage.phoneNumber}
              onFocus={() =>
                setErrorMessage({ ...errorMessage, phoneNumber: '' })
              }
            />
            <Input
              label="email"
              type="email"
              name="email"
              placeholder="Enter your email"
              value={bookCallData.email}
              onChange={(e) =>
                setBookCallData({
                  ...bookCallData,
                  email: e.target.value,
                })
              }
              errorMsg={errorMessage.email}
              onFocus={() => setErrorMessage({ ...errorMessage, email: '' })}
            />
            <TextArea
              label="Description (optional)"
              value={bookCallData.description}
              onChange={(e) =>
                setBookCallData({
                  ...bookCallData,
                  description: e.target.value,
                })
              }
            />
          </div>
          <div className={styles.cancelAndSubmitBtnStyles}>
            <Button
              title="Cancel"
              onClick={() => {
                setIsModalOpen({
                  ...isModalOpen,
                  bookCallOne: false,
                });
                setErrorMessage({});
              }}
              customBtnTitleStyles={styles.cancelBtnTitleStyles}
              customBtnContainerStyles={styles.cancelBtnStyles}
            />
            <Button
              title="Submit"
              onClick={() => {
                handleBookCallValidations();
              }}
              customBtnTitleStyles={styles.submitBtnTitleStyles}
              customBtnContainerStyles={styles.submitBtnStyles}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderBannerSection = () => {
    return (
      <div className={styles.bannerImageContainerStyles}>
        <Image
          image={bannerImage}
          altText="bannerImage"
          customImageContainerStyles={styles.bannerImageStyles}
          customImageStyles={styles.bannerImageContainStyles}
        />
      </div>
    );
  };

  const renderBannerInfoSection = () => {
    return (
      <div className={styles.bannerInfoTextStyles}>
        Our platform seamlessly integrates with LinkedIn, empowering your team
        to effortlessly amplify your brand's reach. With automated reposting, we
        make it easy for every employee to become a powerful brand ambassador.
      </div>
    );
  };

  const renderHowItWorksSection = () => {
    return (
      <div className={styles.howItWorksBlockStyles}>
        <h2 className={styles.howItWorksHeadingStyles}>How it works?</h2>
        <div className={styles.middleHowItWorkCardStyles}>
          {howItWorksData?.map((item, index) => {
            const isLastItem = index === howItWorksData.length - 1;
            return (
              <HowItWorksCard
                key={index}
                title={item?.title}
                description={item?.description}
                customTitleTextStyles={styles.howItWorksTextStyles}
                customDescriptionTextStyles={styles.howItWorksDescStyles}
              />
            );
          })}
        </div>
        <Button
          title="Book a call"
          image={rightArrowWhiteBgIcon}
          altText="rightArrowWhiteBgIcon"
          onClick={() =>
            setIsModalOpen({
              ...isModalOpen,
              bookCallOne: !isModalOpen.bookCallOne,
            })
          }
          customBtnContainerStyles={styles.howItWorkBtnStyles}
          customBtnTitleStyles={styles.bookCallBtnTitleStyles}
        />
      </div>
    );
  };

  const renderWhyChooseUsSection = () => {
    return (
      <div className={styles.whyChooseBlockStyles}>
        <div className={styles.whyChooseSubBlockStyles}>
          <div className={styles.whyChooseViewTextStyles}>Why choose us?</div>
          <div className={styles.whyChooseCardViewStyles}>
            {whyChooseUsData?.map((item, index) => {
              return (
                <WhyChooseUsCard
                  index={index}
                  key={index}
                  title={item?.title}
                  subTitle={item?.subTitle}
                  description={item?.description}
                  customCardContainerStyles={
                    index === 0
                      ? styles.hideWhyChooseCardStyles
                      : index === 3
                      ? styles.activeWhyChooseCardStyes
                      : styles.whyChooseCardStyles
                  }
                  customTitleTextStyles={
                    index === 3
                      ? styles.activeWhyChooseTitleStyles
                      : styles.whyChooseTitleStyles
                  }
                  customSubTitleTextStyles={
                    index === 3
                      ? styles.activeWhyChooseSubTitleStyles
                      : styles.whyChooseSubTitleStyles
                  }
                  customDescriptionTextStyles={
                    index === 3
                      ? styles.activeWhyChooseDescStyles
                      : styles.whyChooseDescStyles
                  }
                  customCardSubContentStyles={
                    styles.whyChooseCardSubContentStyles
                  }
                />
              );
            })}
          </div>
        </div>
        <Button
          title="Book a call"
          image={rightArrowWhiteBgIcon}
          altText="rightArrowWhiteBgIcon"
          onClick={() =>
            setIsModalOpen({
              ...isModalOpen,
              bookCallOne: !isModalOpen.bookCallOne,
            })
          }
          customBtnContainerStyles={styles.whyChooseBtnStyles}
          customBtnTitleStyles={styles.whyChooseBtnTitleStyles}
        />
      </div>
    );
  };

  const renderServicesSection = () => {
    return (
      <div className={styles.servicesBlockStyles}>
        <div className={styles.servicesHeadingViewStyles}>Services</div>
        <div className={styles.bottomServiceBlockStyles}>
          <div className={styles.progreeBarStyles}>
            {pregressBarData?.map((item, index) => {
              return (
                <div className={styles.progressSubViewStyles} key={index}>
                  <p
                    className={
                      selectStep.stepTitle === item.stepTitle
                        ? styles.activeStepTitleStyles
                        : styles.stepTitleTextStyles
                    }
                    onClick={() => tapOnServicesStepClick(item)}
                  >
                    {item?.stepTitle}
                  </p>
                  {item?.stepImage && (
                    <Image
                      image={
                        selectStep.stepTitle === '1'
                          ? item?.stepImage
                          : selectStep.stepTitle === '2'
                          ? progressStepOneImg
                          : selectStep.stepTitle === '3'
                          ? progressStepOneImg
                          : null
                      }
                      altText={item?.stepTitle}
                      customImageContainerStyles={styles.progressBarImgStyles}
                    />
                  )}
                </div>
              );
            })}
          </div>

          <div className={styles.progressBarMobileStyles}>
            <div className={styles.progressBarMobileInsideStyles}>
              {pregressBarMobileData?.map((item, index) => {
                return (
                  <div
                    className={styles.progressSubMobileViewStyles}
                    key={index}
                  >
                    <p
                      className={
                        selectStep.stepTitle === item.stepTitle
                          ? styles.activeStepTitleStyles
                          : styles.stepTitleTextStyles
                      }
                      onClick={() => tapOnServicesStepClick(item)}
                    >
                      {item?.stepTitle}
                    </p>
                    {item?.stepImage && (
                      <Image
                        image={
                          selectStep.stepTitle === '1'
                            ? item?.stepImage
                            : selectStep.stepTitle === '2'
                            ? progressBarMobileImg
                            : selectStep.stepTitle === '3'
                            ? progressBarMobileImg
                            : null
                        }
                        altText={item?.stepTitle}
                        customImageContainerStyles={
                          styles.progreeBarMobileImgStyles
                        }
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <div className={styles.servicePostInfoStyles}>
            <h5 className={styles.servicePostHeadingStyles}>
              Automated Reposting
            </h5>
            <p className={styles.servicePostDescStyles}>
              Effortlessly share your employer’s LinkedIn posts.
            </p>
          </div>
          <div className={styles.serviceImgSectionStyles}>
            <div className={styles.serviceImgInsideStyles}>
              {servicesImageData?.map((item, index) => {
                if (index + 1 === parseInt(selectStep.stepTitle)) {
                  return (
                    <img
                      key={index}
                      src={item?.image}
                      alt="services-img"
                      className={styles.serviceImgStyles}
                    />
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.howItWorksContainerStyles}>
      {renderTopInfoSection()}
      <div className={styles.bannerContainerStyles}>
        {renderBannerSection()}
        {renderBannerInfoSection()}
      </div>
      {renderHowItWorksSection()}
      {renderWhyChooseUsSection()}
      {renderServicesSection()}
    </div>
  );
};

export default HowItWorks;
