import {
  mailIcon,
  phoneIcon,
  progressBarMobileImg,
  progressMobileStepOneImg,
  progressStepOneImg,
  progressStepTwoImg,
  rightUpArrowIcon,
} from 'resources/images';

export const menuData = ['How it works', 'About us', 'Contact us'];

export const footerContactData = [
  {
    leftTitle: 'Know more',
    rightTitle: 'Get in touch',
    image: rightUpArrowIcon,
  },
  {
    leftTitle: 'Call us',
    rightTitle: '+09 ***787 8786 77',
    image: phoneIcon,
  },
  {
    leftTitle: 'Mail us',
    rightTitle: 'repost.linkedin@gmail.com',
    image: mailIcon,
  },
];

export const footerAddressData = [
  {
    title: 'Services',
    description: 'repost',
  },
  {
    title: 'Office',
    description: '23 floor address line1 Street , city zip',
  },
  {
    title: 'Contact',
    description: 'Mail',
  },
];

export const howItWorksData = [
  {
    title: 'Connect Your Accounts',
    description:
      'Link your personal LinkedIn profile to your employer’s account securely with just a few clicks.',
  },
  {
    title: 'Automatic Reposts',
    description:
      'Whenever your employer posts new content on LinkedIn, it will automatically be shared through your personal profile.',
  },
  {
    title: 'Engage and Amplify',
    description:
      'Add your own comments to personalize the reposts or create new posts to further enhance reach and engagement.',
  },
];

export const whyChooseUsData = [
  {
    title: '',
    subTitle: '',
    description: '',
  },
  {
    title: '50 %',
    subTitle: 'Enhanced Visibility',
    description:
      "Increase the visibility of your employer's content exponentially through automated employee advocacy.",
  },
  {
    title: '40 %',
    subTitle: 'Brand Building',
    description:
      'Strengthen employer branding by leveraging the networks of employees on LinkedIn.',
  },
  {
    title: '50 %',
    subTitle: 'Increased Engagement',
    description:
      'Keep your LinkedIn profile active and engaging without the extra effort of curating content.',
  },
];

export const pregressBarData = [
  {
    stepTitle: '1',
    stepImage: progressStepOneImg,
  },
  {
    stepTitle: '2',
    stepImage: progressStepTwoImg,
  },
  {
    stepTitle: '3',
  },
];

export const pregressBarMobileData = [
  {
    stepTitle: '1',
    stepImage: progressBarMobileImg,
  },
  {
    stepTitle: '2',
    stepImage: progressMobileStepOneImg,
  },
  {
    stepTitle: '3',
  },
];
