import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';

const WhyChooseUsCard = (props) => {
  const {
    index,
    title,
    subTitle,
    description,
    customCardContainerStyles,
    customTitleTextStyles,
    customSubTitleTextStyles,
    customDescriptionTextStyles,
    customCardSubContentStyles,
  } = props;
  return (
    <div
      className={classNames(
        styles.cardContainerStyles,
        customCardContainerStyles
      )}
    >
      <h5 className={classNames(styles.titleTextStyles, customTitleTextStyles)}>
        {title}
      </h5>
      <div
        className={classNames(
          styles.cardSubContentViewStyles,
          customCardSubContentStyles
        )}
      >
        <h6
          className={classNames(
            styles.subTitleTextStyles,
            customSubTitleTextStyles
          )}
        >
          {subTitle}
        </h6>
        <p
          className={classNames(
            styles.descriptionTextStyles,
            customDescriptionTextStyles
          )}
        >
          {description}
        </p>
      </div>
    </div>
  );
};

export default WhyChooseUsCard;
