import LayOut from 'layout';
import HowItWorks from 'pages/howitworks';
import React, { Children } from 'react';
import Routes from 'routes';

function App() {
  return (
    <div className="App">
      <LayOut>
        <HowItWorks />
      </LayOut>
    </div>
  );
}

export default App;
