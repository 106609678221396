import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const Image = (props) => {
  const {
    image,
    altText,
    onClick = () => {},
    customImageContainerStyles,
    customImageStyles,
  } = props;
  return (
    <div
      className={classNames(
        styles.imageContainerStyles,
        customImageContainerStyles
      )}
      onClick={onClick}
    >
      <img
        src={image}
        alt={altText}
        className={classNames(styles.imageStyles, customImageStyles)}
      />
    </div>
  );
};

Image.propTypes = {
  altText: PropTypes.string,
};

export default Image;
