import React from 'react';
import Image from 'components/common/image';
import classNames from 'classnames';
import styles from './styles.module.css';

const Button = (props) => {
  const {
    title,
    image,
    altText,
    onClick,
    customBtnTitleStyles,
    customBtnContainerStyles,
    customBtnImageContainerStyles,
    customBtnImageStyles,
  } = props;
  return (
    <button
      className={classNames(
        styles.btnContainerStyles,
        customBtnContainerStyles
      )}
      onClick={onClick}
    >
      {title && (
        <p className={classNames(styles.titleStyles, customBtnTitleStyles)}>
          {title}
        </p>
      )}
      {image && (
        <Image
          image={image}
          altText={altText}
          customImageContainerStyles={classNames(
            styles.btnImageContainerStyles,
            customBtnImageContainerStyles
          )}
          customImageStyles={classNames(
            styles.btnImageStyles,
            customBtnImageStyles
          )}
        />
      )}
    </button>
  );
};

export default Button;
