import React from 'react';
import { footerAddressData, footerContactData } from 'constants/commondata.js';
import Image from 'components/common/image';
import styles from './styles.module.css';

const Footer = () => {
  const renderTopTitleSection = () => {
    return <h2 className={styles.linkedInTextStyles}>Linked in.</h2>;
  };

  const renderMiddleContactSection = () => {
    return (
      <div className={styles.contactBlockStyles}>
        {footerContactData?.map((item, index) => {
          return (
            <div className={styles.contactContentViewStyles} key={index}>
              <p className={styles.contactLeftTitleStyles}>{item?.leftTitle}</p>
              <div className={styles.contactRightContentStyles}>
                <p className={styles.contactRightTitleStyles}>
                  {item?.rightTitle}
                </p>
                <Image
                  image={item.image}
                  altText={item?.rightTitle}
                  customImageContainerStyles={styles.contactImageStyles}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderBottomAddressSection = () => {
    return (
      <div className={styles.bottomAddressBlockStyles}>
        <div className={styles.bottomAddressSubBlockStyles}>
          {footerAddressData?.map((item, index) => {
            return (
              <div className={styles.topAddressDataViewStyles} key={index}>
                <h5 className={styles.addressTitleStyles}>{item?.title}</h5>
                <p className={styles.addressDescStyles}>{item?.description}</p>
              </div>
            );
          })}
        </div>
        <p className={styles.bottomCopyRightTextStyles}>
          © 2024 repost.linkedin - All RIGHTS RESERVED
        </p>
      </div>
    );
  };

  return (
    <div className={styles.footerContainerStyles}>
      {renderTopTitleSection()}
      {renderMiddleContactSection()}
      {renderBottomAddressSection()}
    </div>
  );
};

export default Footer;
