import React from 'react';
import styles from './styles.module.css';
import classNames from 'classnames';

const HowItWorksCard = (props) => {
  const {
    title,
    description,
    customTitleTextStyles,
    customDescriptionTextStyles,
  } = props;
  return (
    <div className={styles.cardContainerStyles}>
      <h5 className={classNames(styles.titleTextStyles, customTitleTextStyles)}>
        {title}
      </h5>
      <p
        className={classNames(
          styles.descriptionTextStyles,
          customDescriptionTextStyles
        )}
      >
        {description}
      </p>
    </div>
  );
};

export default HowItWorksCard;
