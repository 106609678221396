import React, { useState } from 'react';
import { menuData } from 'constants/commondata.js';
import Image from 'components/common/image';
import styles from './styles.module.css';
import { closeIcon, hambergerIcon } from 'resources/images';

const Header = () => {
  // states:
  const [activeMenuItem, setActiveMenuItem] = useState(menuData[0]);
  const [showMenu, setShowMenu] = useState(false);
  const [activeHambergerMenuItem, setActiveHambergerMenuItem] = useState(
    menuData[0]
  );

  //  function to handle menu item click
  const handleMenuItemClick = (item) => {
    setActiveMenuItem(item);
  };

  const renderMenuDataSection = () => {
    return (
      <div className={styles.menuDataContainerStyles}>
        {menuData?.map((item, index) => {
          return (
            <p
              className={
                activeMenuItem === item
                  ? styles.activeMenuItemTextStyles
                  : styles.menuItemTextStyles
              }
              key={index}
              onClick={() => handleMenuItemClick(item)}
            >
              {item}
            </p>
          );
        })}
      </div>
    );
  };

  const renderHambergerMenuSection = () => {
    return (
      <div className={styles.hamberMenuBlockStyles}>
        <Image
          image={hambergerIcon}
          altText="hambergerIcon"
          onClick={() => setShowMenu(!showMenu)}
          customImageContainerStyles={
            showMenu === true
              ? styles.removeHambergerMenuStyles
              : styles.hamberMenuImageStyles
          }
        />
        <div className={styles.showHambergerMenuStyles}>
          {renderShowMenuSection()}
        </div>
      </div>
    );
  };

  const renderShowMenuSection = () => {
    return (
      <React.Fragment>
        {showMenu && (
          <div className={styles.hambergerMenuItemViewStyles}>
            <div className={styles.closeImgBlockStyles}>
              <Image
                image={closeIcon}
                altText="closeIcon"
                onClick={() => setShowMenu(!showMenu)}
                customImageContainerStyles={styles.closeImageImageStyles}
              />
            </div>
            <div className={styles.hambergerMenuDataBlockStyles}>
              {menuData?.map((item, index) => {
                return (
                  <p
                    className={
                      activeHambergerMenuItem === item
                        ? styles.activeHamberMenuItemTextStyles
                        : styles.hambergerMenuItemTextStyles
                    }
                    key={index}
                    onClick={() => {
                      setActiveHambergerMenuItem(item);
                      setShowMenu(false);
                    }}
                  >
                    {item}
                  </p>
                );
              })}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {renderMenuDataSection()}
      {renderHambergerMenuSection()}
    </React.Fragment>
  );
};

export default Header;
