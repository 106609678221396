import {
  linkedinPostOne,
  linkedinPostTwo,
  servicesImg,
} from 'resources/images';

export const servicesImageData = [
  {
    image: servicesImg,
  },
  {
    image: linkedinPostOne,
  },
  {
    image: linkedinPostTwo,
  },
];
