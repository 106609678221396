import React from 'react';
import styles from './styles.module.css';

const TextArea = (props) => {
  const { label, value, onChange, placeholder } = props;
  return (
    <div className={styles.textAreaContainerStyles}>
      {label && <label className={styles.labelTextStyles}>{label}</label>}
      <textarea
        className={styles.textareaStyles}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextArea;
