import React, { useEffect } from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';

const Modal = (props) => {
  // props:
  const {
    isOpen,
    onClose,
    children,
    customModalContentStyles,
    customModalOverLayStyles,
  } = props;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className={classNames(styles.modalOverlay, customModalOverLayStyles)}
      onClick={onClose}
    >
      <div
        className={classNames(styles.modalContent, customModalContentStyles)}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
